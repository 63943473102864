import React from "react";
import CardSkeleton from "./CardSkeleton";

const CardListSkeleton = () => {
  return (
    <div className="container py-11 animate-pulse px-5 mx-auto grid grid-cols-1 gap-8 mt-10 xl:mt-12 xl:gap-12 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3">
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </div>
  );
};

export default CardListSkeleton;
