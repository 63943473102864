import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { calculateDiscountPercentage } from "../utils/commonUtil";
import { Carousel } from "antd";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cartSlice";

const ProductCard = ({ product }) => {
  const { name, images, brand, description, price, mrp, _id } = product;
  const dispatch = useDispatch();

  const addItemToCart = (item) => {
    dispatch(addToCart(item));
  };

  return (
    <article className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 ">
      <Link to={`/product/${_id}`}>
        <div className="relative flex items-center justify-center overflow-hidden aspect-square rounded-xl">
          <img src={images[0]} alt={name} />
        </div>
      </Link>

      <div className="mt-1 p-2">
        <h2 className="text-slate-700">{name}</h2>
        <p className="mt-1 text-sm text-slate-400">{brand}</p>
        <div className="mt-3 flex items-end justify-between">
          <div className="font-bold text-sm text-blue-500">
            <div
              style={{
                textDecoration: "line-through",
                fontSize: "12px",
                color: "gray",
              }}
            >
              ₹ {mrp}{" "}
            </div>
            ₹ {price} ({calculateDiscountPercentage(mrp, price)} off)
          </div>

          <div className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-4 py-1.5 text-white duration-100 hover:bg-blue-600">
            <FontAwesomeIcon icon={faShoppingBag} />
            <button
              className="text-sm"
              onClick={() => {
                addItemToCart(product);
              }}
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ProductCard;
