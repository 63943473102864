import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { calculateTotal } from "../utils/commonUtil";
import Login from "../components/Login";

const Checkout = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const total = useMemo(() => calculateTotal(cartItems), [cartItems]);

  console.log(cartItems);
  return (
    <div className="grid grid-cols-5 gap-3 p-10">
      <div className="col-span-3 border p-4 bg-white rounded">
        <Login />
      </div>
      <div className="col-span-2 border p-4 bg-white rounded">
        <p className="font-bold text-lg mb-2 pb-2 border-b">Order Summary</p>
        {cartItems.map((item) => {
          return (
            <div className="flex pb-4 gap-4 border-b mb-2">
              <img className="w-36" src={item.images[0]} alt={item.name} />
              <div>
                <p className="text-sm">{item.name}</p>
                <div className="flex text-sm justify-between mt-3">
                  <span>Qty : {item.quantity}</span>
                  <span>
                    Price:{" "}
                    <span className="font-bold text-gray-600">
                      ₹{item.price}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex flex-col pb-4 gap-4 mb-2">
          <div className="flex justify-between">
            <span>Sub Total: </span>
            <span>₹{total}</span>
          </div>
          <div className="flex justify-between pb-4 border-b">
            <span>Delivery Charges: </span>
            <span>FREE</span>
          </div>
          <div className="flex justify-between">
            <span>Total: </span>
            <span>₹{total}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
