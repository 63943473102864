import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Body from "./components/Body";
import MainContainer from "./components/MainContainer";
import Admin from "./pages/Admin";
import { Provider } from "react-redux";
import appStore from "./store/appStore";
import Category from "./components/Category";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";
const appRoute = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
    children: [
      {
        path: "",
        element: <MainContainer />,
      },
      {
        path: "category/:id",
        element: <Category />,
      },
      {
        path: "product/:productId",
        element: <Product />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
  },
]);

function App() {
  return (
    <Provider store={appStore}>
      <RouterProvider router={appRoute}></RouterProvider>
    </Provider>
  );
}

export default App;
