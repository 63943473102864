import { useEffect, useState } from "react";
import ProductList from "./ProductList";
import httpService from "../utils/httpService";
import { GET_ALL_PRODUCTS } from "../constants/ApiUrls";
import CardListSkeleton from "./CardListSkeleton";

const MainContainer = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await httpService({
          method: "get",
          url: GET_ALL_PRODUCTS,
        });

        if (response && response?.data) {
          setProducts(response?.data);
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  if (isLoading) return <CardListSkeleton />;

  if (products.length < 1) return null;
  return <ProductList products={products} />;
};

export default MainContainer;
