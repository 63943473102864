import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_PRODUCTS_BY_CATID } from "../constants/ApiUrls";
import httpService from "../utils/httpService";
import ProductList from "./ProductList";
import CardListSkeleton from "./CardListSkeleton";

const Category = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const fetchProductsByCatId = async () => {
      setIsloading(true);
      try {
        const response = await httpService({
          method: "get",
          url: GET_PRODUCTS_BY_CATID + "/" + id,
        });
        if (response && response?.data) {
          setProducts(response?.data?.products);
        } else {
          setProducts([]);
        }
      } catch (e) {
        console.log(e);
      }
      setIsloading(false);
    };

    fetchProductsByCatId();
  }, [id]);
  if (isLoading) return <CardListSkeleton />;
  if (products.length === 0)
    return (
      <div className="w-[100%] h-96 mx-auto flex justify-center items-center">
        <p>No Products available under this category</p>
      </div>
    );

  return <ProductList products={products} />;
};

export default Category;
