export const API_URL = process.env.REACT_APP_API_URL;

export const AUTH_URL = "/api/users/auth";
export const LOGOUT = "/api/users/logout";
export const UPLOAD_BLOB = "/api/upload/blob";
export const GET_CATEGORIES = "api/category/categories";
export const CREATE_USER = "/api/users";
export const GET_ALL_PRODUCTS = "/api/products/allproducts";
export const GET_PRODUCTS_BY_CATID = "/api/products/productsByCategory";
export const GET_PRODUCT_BY_ID = (id) => `/api/products/${id}`;
