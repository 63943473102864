import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router";

const Body = () => {
  return (
    <div className="bg-gray-100">
      <Header />
      <div className="container mx-auto mt-16">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Body;
