import appStore from "../store/appStore";
import { setLoader } from "../store/appSlice";

const setInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (request) => {
      appStore.dispatch(setLoader(true));

      switch (request.status) {
        case 200:
          appStore.dispatch(setLoader(true));
          return request;
        default:
          return request;
      }
    },
    (error) => {
      if (error.request) {
        appStore.dispatch(setLoader(false));
        switch (error.request.status) {
          case 401:
            console.log("Unauthorized Access");
            return;
          default:
            console.log("not found");
        }
      }
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      appStore.dispatch(setLoader(false));
      switch (response.status) {
        case 200:
          return response;
        default:
          return response;
      }
    },
    (error) => {
      if (error.response) {
        appStore.dispatch(setLoader(false));
        switch (error.response.status) {
          case 401:
            console.log("Unauthorized Access");
            return;
          default:
            console.log("not found");
        }
      }
    }
  );
};

export default setInterceptor;
