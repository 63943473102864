import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import httpService from "../utils/httpService";
import { GET_PRODUCT_BY_ID } from "../constants/ApiUrls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { calculateDiscountPercentage } from "../utils/commonUtil";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cartSlice";

const Product = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchProductsById = async () => {
      try {
        const response = await httpService({
          method: "get",
          url: GET_PRODUCT_BY_ID(productId),
        });
        console.log(response);
        if (response && response?.data) {
          setProduct(response?.data);
        } else {
          setProduct(null);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchProductsById();
  }, [productId]);

  if (!product) return null;

  const addItemToCart = (item) => {
    dispatch(addToCart(item));
  };

  return (
    <div className="py-6 pt-12 bg-white mt-12 h-[85vh]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6">
        <div className="flex flex-col md:flex-row -mx-4">
          <div className="md:flex-1 px-4">
            <div>
              <div className="h-64 md:h-80 rounded-lg bg-gray-100 mb-4">
                <div className="h-64 md:h-80 rounded-lg bg-gray-100 mb-4 flex items-center justify-center">
                  <img src={product.images[0]} alt={product?.name} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex-1 px-4">
            <h2 className="mb-2 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl">
              {product?.name}
            </h2>
            <p className="text-gray-500 text-sm">
              By{" "}
              <Link href="#" className="text-indigo-600 hover:underline">
                {product?.brand}
              </Link>
            </p>
            <div className="flex items-center space-x-4 my-4">
              <div className="flex">
                <div className="rounded-lg flex py-2 px-3 line-through">
                  <span className="text-gray-700 mr-1 mt-1">₹</span>
                  <span className="font-bold gray-indigo-700 text-lg">
                    {product?.mrp}
                  </span>
                </div>
                <div className="rounded-lg bg-gray-100 flex py-2 px-3">
                  <span className="text-indigo-400 mr-1 mt-1">₹</span>
                  <span className="font-bold text-indigo-600 text-3xl">
                    {product?.price}
                  </span>
                </div>
              </div>
              <div className="flex-1">
                <p className="text-green-500 text-xl font-semibold">
                  Save{" "}
                  {calculateDiscountPercentage(product?.mrp, product?.price)}
                </p>
                <p className="text-gray-400 text-sm">Inclusive of all Taxes.</p>
              </div>
            </div>
            <p className="text-gray-500">{product?.description}</p>
            <div className="flex py-4 space-x-4">
              <div className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-4 py-1.5 text-white duration-100 hover:bg-blue-600">
                <FontAwesomeIcon icon={faShoppingBag} />
                <button
                  className="text-sm"
                  onClick={() => addItemToCart(product)}
                >
                  Add to cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
