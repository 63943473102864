export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const calculateDiscountPercentage = (mrp, sellingPrice) => {
  // Ensure that both MRP and Selling Price are non-negative numbers
  if (
    typeof mrp !== "number" ||
    typeof sellingPrice !== "number" ||
    mrp < 0 ||
    sellingPrice < 0
  ) {
    return "";
  }

  // Calculate the discount percentage
  const discount = mrp - sellingPrice;
  const discountPercentage = (discount / mrp) * 100;

  return discountPercentage.toFixed() + "%";
};

export const calculateTotal = (cartItems) => {
  if (!cartItems || cartItems.length === 0) {
    return 0; // Return 0 if the cart is empty or undefined
  }

  // Use reduce to sum up the total cost
  const total = cartItems.reduce((acc, item) => {
    // Ensure item has both 'price' and 'quantity' properties
    if (item && item.price && item.quantity) {
      return acc + item.price * item.quantity;
    }
    return acc;
  }, 0);

  return total;
};
