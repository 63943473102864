import React from "react";

const HeaderSkeleton = () => {
  return (
    <section className="bg-white dark:bg-gray-900 border border-gray-100 border-b-2 ">
      <div className="container flex items-center justify-between p-4 mx-auto animate-pulse sm:space-y-0 sm:flex-row">
        <p className="md:hidden w-8 h-8 bg-gray-200  dark:bg-gray-700"></p>

        <p className="w-32 h-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>

        <div className="hidden md:flex md:flex-wrap md:items-center md:justify-center gap-6 mt-10">
          <p className="w-20 h-5 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-20 h-5 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-20 h-5 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-20 h-5 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-20 h-5 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
        </div>

        <p className="w-8 h-8 bg-gray-200 dark:bg-gray-700"></p>
      </div>
    </section>
  );
};

export default HeaderSkeleton;
