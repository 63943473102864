import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./productSlice";
import appReducer from "./appSlice";
import cartReducer from "./cartSlice";
const appStore = configureStore({
  reducer: {
    product: productReducer,
    app: appReducer,
    cart: cartReducer,
  },
});

export default appStore;
