import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateTotal } from "../utils/commonUtil";
import { removeItemFromCart } from "../store/cartSlice";
import { DeleteFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const CartItems = ({ close }) => {
  const items = useSelector((state) => state.cart.items);
  const total = useMemo(() => calculateTotal(items), [items]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeItem = (item) => {
    dispatch(removeItemFromCart(item));
  };

  const goToCheckout = () => {
    navigate("/checkout");
    close();
  };

  if (items.length < 1) return <p className="text-center">Cart is empty !</p>;
  return (
    <div className="w-full rounded-b border-t-0 z-10">
      <div>
        {items?.map((item) => {
          return (
            <div
              key={item._id}
              className="p-2 flex bg-white hover:bg-gray-100 cursor-pointer border-b border-gray-100"
            >
              <div className="p-2 w-14 h-12">
                <img
                  className="aspect-square"
                  src={item.images[0]}
                  alt={item.name}
                />
              </div>
              <div className="flex-auto text-sm w-32">
                <div className="font-bold">{item.name}</div>
                <div className="truncate">{item.description}</div>
                <div className="text-gray-400">Qt: {item.quantity}</div>
              </div>
              <div className="flex flex-col w-18 font-medium items-end">
                <div
                  className="w-4 h-4 mb-6 hover:bg-red-200 rounded-full cursor-pointer text-red-700"
                  onClick={() => removeItem(item)}
                >
                  <DeleteFilled />
                </div>
                ₹ {item.price}
              </div>
            </div>
          );
        })}

        <div className="p-4 justify-center flex">
          <button
            className="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
        hover:bg-blue-700 hover:text-blue-100 
        bg-blue-100 
        text-blue-700 
        border duration-200 ease-in-out 
        border-blue-600 transition"
            onClick={goToCheckout}
          >
            Checkout ₹{total}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
