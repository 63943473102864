const CardSkeleton = () => {
  return (
    <div className="w-full ">
      <div className="w-full h-56 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

      <h1 className="w-56 h-5 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700">
        {" "}
      </h1>
      <p className="w-[100%] h-3 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
      <div className="w-full flex justify-between">
        <div className="w-[40%] h-5 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></div>
        <div className="w-[40%] h-5 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></div>
      </div>
    </div>
  );
};

export default CardSkeleton;
