import { createSlice } from "@reduxjs/toolkit";
// import httpService from "../utils/httpService";
// import { GET_ALL_PRODUCTS } from "../constants/ApiUrls";

// export const fetchProducts = createAsyncThunk(
//   "mattecurve/products",
//   async () => {
//     try {
//       const response = await httpService({
//         method: "GET",
//         url: GET_ALL_PRODUCTS,
//       });
//       if (response && response.data) {
//         return response.data;
//       }
//     } catch (e) {
//       return [];
//     }
//     return [];
//   }
// );

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    categories: [],
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addCase(fetchProducts.fulfilled, (state, action) => {
  //       state.products = action.payload;
  //     });
  //   },
});

export const { setCategories, setProducts } = productSlice.actions;

export default productSlice.reducer;
