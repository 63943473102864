import { Button, Input } from "antd";
import React, { useState } from "react";

const Login = () => {
  const [type, setType] = useState("guest");
  return (
    <div className="grid grid-cols-3 gap-4 justify-center">
      <div className="col-span-1 flex flex-col gap-2 items-center">
        <Button className="w-[70%]">Continue as Guest</Button>
        <Button className="w-[70%]">Login</Button>
        <Button className="w-[70%]">Sign Up</Button>
      </div>
      <div className="col-span-2">
        <form>
          <label>Email:</label>
          <Input type="email" />
          <label>Password:</label>
          <Input type="password" />
          <Button type="submit">Login</Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
