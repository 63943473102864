import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setCategories, setProducts } from "../store/productSlice";
import httpService from "../utils/httpService";
import { GET_ALL_PRODUCTS, GET_CATEGORIES } from "../constants/ApiUrls";
import { Drawer } from "antd";
import CartItems from "./CartItems";
import HeaderSkeleton from "./HeaderSkeleton";

const Header = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.product.categories);
  const cartItems = useSelector((state) => state.cart.items);
  const [open, setOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const response = await httpService({
          method: "get",
          url: GET_CATEGORIES,
        });

        if (response && response?.data) {
          dispatch(setCategories(response?.data));
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    };
    fetchCategories();
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const onCartClose = () => {
    setCartOpen(false);
  };

  if (isLoading) return <HeaderSkeleton />;

  return (
    <nav className="fixed top-0 left-0 z-20 w-full border-b border-gray-200 bg-white py-2.5 px-6 sm:px-4">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <div className="md:hidden mt-2 sm:mt-0 sm:flex">
          {/* Login Button */}

          {/* 

            <button
            type="button"
            className="rounde mr-3 hidden border border-blue-700 py-1.5 px-6 text-center text-sm font-medium text-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 md:inline-block rounded-lg"
          >
            Login
          </button>
          <button
            type="button"
            className="rounde mr-3 hidden bg-blue-700 py-1.5 px-6 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 md:mr-0 md:inline-block rounded-lg"
          >
            Register
          </button>
        */}

          {/* Register Button */}
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 md:hidden"
            aria-controls="navbar-sticky"
            onClick={() => setOpen(!open)}
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <NavLink className="flex items-center">
          <span className="self-center whitespace-nowrap text-xl font-bold">
            Matte Curve
          </span>
        </NavLink>

        <div
          className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
          id="navbar-sticky"
        >
          <ul className="mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:text-sm md:font-medium">
            {categories &&
              categories?.map((cat) => {
                return (
                  <li key={cat._id}>
                    <NavLink
                      to={`category/${cat._id}`}
                      className="block rounded bg-blue-700 py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 md:text-blue-700"
                      aria-current="page"
                    >
                      {cat.name}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="items-center justify-between md:order-3 md:flex md:w-auto relative">
          <FontAwesomeIcon
            onClick={() => setCartOpen(true)}
            className="text-blue-700 text-xl cursor-pointer"
            icon={faShoppingBag}
          />
          {cartItems?.length !== 0 && (
            <span className="absolute bg-red-700 border-2 border-white rounded-full text-white px-1 text-xs -top-2 left-2">
              {cartItems?.length}
            </span>
          )}
        </div>
      </div>
      <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={onClose}
        open={open}
        key="key"
      >
        <ul className="flex flex-col rounded-lg md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:text-sm md:font-medium">
          {categories &&
            categories?.map((cat) => {
              return (
                <li key={cat._id} className="p-2">
                  <NavLink
                    to={`category/${cat._id}`}
                    className="py-2"
                    aria-current="page"
                    onClick={onClose}
                  >
                    {cat.name}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </Drawer>

      <Drawer
        title="Cart"
        placement="right"
        closable={true}
        onClose={onCartClose}
        open={cartOpen}
        key="right"
      >
        <CartItems close={onCartClose} />
      </Drawer>
    </nav>
  );
};

export default Header;
