import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item._id === newItem._id);

      if (existingItem) {
        // If item already exists, increase its quantity
        existingItem.quantity += 1;
      } else {
        // If item doesn't exist, add it to the cart
        state.items.push({ ...newItem, quantity: 1 });
      }
    },
    removeItemFromCart: (state, action) => {
      const itemIdToRemove = action.payload._id;
      state.items = state.items.filter((item) => item._id !== itemIdToRemove);
    },

    emptyCart: (state) => {
      state.items = [];
    },
  },
});

export const { addToCart, removeItemFromCart, emptyCart } = cartSlice.actions;
export default cartSlice.reducer;
